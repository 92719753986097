<template>
  <section>
    <span class="period"> <p >Atualizado em: {{ formatDate(company.createdAt) }}</p></span>
    <div class="container mt-2">
      <!--  menu clicável -->
      <ul class="nav nav-pills mb-3" id="menu" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="integracoes-tab"
            data-toggle="tab"
            href="#integracoes"
            role="tab"
            aria-controls="integracoes"
            aria-selected="true"
            >Integrações</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="atendimento-tab"
            data-toggle="tab"
            href="#atendimento"
            role="tab"
            aria-controls="atendimento"
            aria-selected="false"
            >Atendimento</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="efficiency-tab"
            data-toggle="tab"
            href="#efficiency"
            role="tab"
            aria-controls="efficiency"
            aria-selected="false"
            >Eficiência</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="escale-tab"
            data-toggle="tab"
            href="#escale"
            role="tab"
            aria-controls="escale"
            aria-selected="false"
            >Escala</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="success-tab"
            data-toggle="tab"
            href="#success"
            role="tab"
            aria-controls="success"
            aria-selected="false"
            >Sucesso</a
          >
        </li>
      </ul>
      <!-- Conteúdo da lista plano light -->

      <div v-if="company.account_type === 'essential'" class="tab-content" id="content">
      
      <!-- Integracoes -->
        <div
          class="tab-pane fade show active"
          id="integracoes"
          role="tabpanel"
          aria-labelledby="integracoes-tab"
        >
          <h3>Canal</h3>
          <div class="table-responsive">
            <table class="table table-bordered">
              <tbody>
                <tr v-for="(item, index) in integrationDataEssential" :key="index">
                  <th class="text-left">{{ item.integration }}</th>
                  <th class="text-right">
                    <span v-if="item.integration === 'Whatsapp'">
                      <span
                        v-if="company.whatsapp_official_integration === true"
                        class="fa fa-check green-icon green-icon" 
                      ></span>
                      <span v-else class="fa fa-regular fa-ban red-icon"></span>
                    </span>
                    <span v-else="item.integration === 'Instagram'">
                      <span
                        v-if="
                          company.instagram_integration == true ||
                            company.instagram_integration === 'true'
                        "
                        class="fa fa-check green-icon"
                      ></span>
                      <span v-else class="fa fa-regular fa-ban red-icon"></span>
                    </span>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Atendimento -->
        <div
          class="tab-pane fade"
          id="atendimento"
          role="tabpanel"
          aria-labelledby="atendimento-tab"
        >
          <h3>Tipo</h3>
          <div class="table-responsive">
            <table class="table table-bordered">
              <tbody>
                <tr v-for="(item, index) in attendanceDatasAny" :key="index">
                  <th class="text-left">{{ item.attendance }}</th>
                  <th class="text-right">
                    <span v-if="item.attendance === 'Setores'">
                      <span
                        v-if="company.departments"
                        class="fa fa-check green-icon"
                      ></span>
                      <span v-else class="fa fa-regular fa-ban red-icon"></span>
                    </span>
                    <span v-else-if="item.attendance === 'Tabulações'">
                      <span
                        v-if="company.tabulations"
                        class="fa fa-check green-icon"
                      ></span>
                      <div v-else>
                        <span class="fa fa-regular fa-ban red-icon"></span>
                      </div>
                    </span>
                    <span v-else>
                      <span
                        v-if="company.active_tags"
                        class="fa fa-check green-icon"
                      ></span>
                      <span v-else class="fa fa-regular fa-ban red-icon"></span>
                    </span>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Eficiencia -->
        <div
          class="tab-pane fade"
          id="efficiency"
          role="tabpanel"
          aria-labelledby="efficiency-tab"
        >
          <h3>Tipo</h3>
          <table class="table table-bordered">
            <tbody>
              <tr v-for="(item, index) in efficiencyDatasAny" :key="index">
                <th class="text-left">{{ item.efficiency }}</th>
                <th class="text-right">
                  <span v-if="item.efficiency === 'Variáveis Cadastradas'">
                    <span
                      v-if="company.total_variables"
                      class="fa fa-check green-icon"
                    ></span>
                    <span v-else class="fa fa-regular fa-ban red-icon"></span>
                  </span>
                  <span
                    v-else-if="
                      item.efficiecy === 'Mensagens Rápidas Cadastradas'
                    "
                  >
                    <span
                      v-if="company.trigger_messages"
                      class="fa fa-check green-icon"
                    ></span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon"></span>
                    </div>
                  </span>
                  <span v-else>
                    <span v-if="company.active_tags" class="fa fa-check green-icon"></span>
                    <span v-else class="fa fa-regular fa-ban red-icon"></span>
                  </span>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Escala -->
        <div
          class="tab-pane fade"
          id="escale"
          role="tabpanel"
          aria-labelledby="escale-tab"
        >
          <h3></h3>
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">Não existe exigência de escala atualmente para planos essenciais.</th>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Sucesso -->
        <div
          class="tab-pane fade"
          id="success"
          role="tabpanel"
          aria-labelledby="success-tab"
        >
          <h3>Tipo</h3>
          <table class="table table-bordered">
            <tbody>
              <tr v-for="(item, index) in successDatasEssential" :key="index">
                <th class="text-left">{{ item.success }}</th>
                <th class="text-right">
                  <span
                    v-if="
                      item.success ===
                        'Uma nova template cadastrada nos últimos 30 dias'
                    "
                  >
                    <span
                      v-if="company.templatesCountThirtyDays > 0"
                      class="fa fa-check green-icon"
                    ></span>
                    <span v-else class="fa fa-regular fa-ban red-icon"></span>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        '80% da base de usuários operadores ativos (login nos últimos 7 dias)'
                    "
                  >
                    <span
                      v-if="company.lastOperatorsAccesSevenDays >= 80"
                      class="fa fa-check green-icon"
                    ></span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon"></span>
                    </div>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        '50% da base de usuários administradores ativos (login nos últimos 7 dias)'
                    "
                  >
                    <span
                      v-if="company.lastAdminsAccesSevenDays >= 50"
                      class="fa fa-check green-icon"
                    ></span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon"></span>
                    </div>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Acesso à tela de monitoramento no mínimo uma vez nos 7 últimos dias'
                    "
                  >
                  
                    <span
                      v-if="shouldShowMonitoringIn7Days()"
                      class="fa fa-check green-icon"
                    ></span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon"></span>
                    </div>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Acesso à tela de dashboard no mínimo uma vez nos 7 últimos dias'
                    "
                  >
                    <span
                      v-if="shouldShowDashboardIn7Days()"
                      class="fa fa-check green-icon"
                    ></span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon"></span>
                    </div>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Troca maior que 7000 interações em mensagens nos últimos 7 dias.'
                    "
                  >
                    <span
                      v-if="company.lastSevenInterations.totalMessages >= 7000"
                      class="fa fa-check green-icon"
                    ></span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon"></span>
                    </div>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Ao menos 50% das conversas iniciadas pelos operadores foram finalizadas nos últimos 7 dias'
                    "
                  >
                    <span
                      v-if="company.lastCallsSevenDaysByOperator >= 50"
                      class="fa fa-check green-icon"
                    ></span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon"></span>
                    </div>
                  </span>

                  <span v-else>
                    <span
                      v-if="company.growthPercentageContactsSevenDays >= 1"
                      class="fa fa-check green-icon"
                    ></span>
                    <span v-else class="fa fa-regular fa-ban red-icon"></span>
                  </span>
                </th>
                <th class="text-right">
                  <span
                    v-if="
                      item.success ===
                        'Uma nova template cadastrada nos últimos 30 dias'
                    "
                  >
                    <span
                      v-if="company.templatesCountThirtyDays <= 0"
                     class="text-center p-1 m-2 ml-2"
                    >
                    <span class=" red-icon" > 
                      <font-awesome-icon :icon="['fas','arrow-trend-down']" />
                    <span class="text-by-side-icon">
                      {{ formatDate(company.last_template)}}
                      </span>
                    </span>
                  </span>
                    <span v-else class="green-icon">
                      <font-awesome-icon :icon="['fas','arrow-trend-up']" />
                    
                      <span class="text-by-side-icon">
                        {{ formatDate(company.last_template)}}
                        </span>
                    </span>
                    </span>
                  <span
                    v-else-if="
                      item.success ===
                        '80% da base de usuários operadores ativos (login nos últimos 7 dias)'
                    "
                  >
                  <span
                      v-if="company.lastOperatorsAccesSevenDays < 80"
                     class="text-center p-34 m-2"
                    >
                    <span class="red-icon">
                      <font-awesome-icon :icon="['fas','arrow-trend-down']" />
                      <span class="text-by-side-icon">
                        {{`${company.lastOperatorsAccesSevenDays} %`}}
                      </span>
                    </span>
                  </span>
                    <span v-else class="green-icon">
                      <font-awesome-icon :icon="['fas','arrow-trend-down']" />
                      <span class="text-by-side-icon">
                        {{`${company.lastOperatorsAccesSevenDays} %`}}
                      </span>
                    </span>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        '50% da base de usuários administradores ativos (login nos últimos 7 dias)'
                    "
                  >
                  <span
                    v-if="company.lastAdminsAccesSevenDays < 50"
                     class="text-center p-34 m-2"
                    >
                    <span class="red-icon" > 
                      <font-awesome-icon :icon="['fas','arrow-trend-down']" />
                      <span class="text-by-side-icon">
                        {{`${company.lastAdminsAccesSevenDays} %`}}
                      </span>
                    </span>
                  </span>
                    <span v-else class="green-icon">
                      <font-awesome-icon :icon="['fas','arrow-trend-up']" />
                      <span class="text-by-side-icon">
                        {{`${company.lastOperatorsAccesSevenDays} %`}}
                      </span>
                    </span>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Acesso à tela de monitoramento no mínimo uma vez nos 7 últimos dias'
                    "
                  >
                    <span
                    v-if="shouldShowMonitoringIn7Days()"
                     class="text-center p-34 m-2"
                    >
                    <span class="green-icon">
                  <font-awesome-icon :icon="['fas', 'arrow-trend-up']" />
                      <span class="text-by-side-icon">
                        {{formatDate(company.last_access_monitoring)}}
                      </span>
                    </span>
                  </span>
                    <span v-else class="red-icon">
                      <font-awesome-icon :icon="['fas', 'arrow-trend-down']" />
                      <span class="text-by-side-icon">
                        {{formatDate(company.last_access_monitoring)}}
                      </span>
                    </span>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Acesso à tela de dashboard no mínimo uma vez nos 7 últimos dias'
                    "
                  >
                  <span
                    v-if="shouldShowDashboardIn7Days()"
                     class="text-center p-34 m-2"
                    >
                    <span class="green-icon" > 
                  <font-awesome-icon :icon="['fas', 'arrow-trend-up']" />
                      <span class="text-by-side-icon">
                        {{formatDate(company.last_access_dashboard)}}
                      </span>
                    </span>
                  </span>
                  <span v-else class="red-icon">
                      <font-awesome-icon :icon="['fas', 'arrow-trend-down']" />
                      <span class="text-by-side-icon">
                        {{formatDate(company.last_access_dashboard)}}
                      </span>
                    </span>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Troca maior que 7000 interações em mensagens nos últimos 7 dias.'
                    "
                  >
                  <span
                    v-if="company.lastSevenInterations.totalMessages < 7000"
                     class="text-center p-34 m-2"
                    >
                    <span class="red-icon" > 
                      <font-awesome-icon :icon="['fas', 'arrow-trend-down']" />
                      <span class="text-by-side-icon">
                        {{company.lastSevenInterations.totalMessages}}
                      </span>
                    </span>
                  </span>
                  <span v-else class="green-icon">
                      <font-awesome-icon :icon="['fas','arrow-trend-up']" />
                      <span class="text-by-side-icon">
                        {{company.lastSevenInterations.totalMessages}}
                      </span>
                    </span>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Ao menos 50% das conversas iniciadas pelos operadores foram finalizadas nos últimos 7 dias'
                    "
                  >
                  <span
                    v-if="company.lastCallsSevenDaysByOperator < 50"
                     class="text-center p-34 m-2"
                    >
                    <span class="red-icon" > 
                      <font-awesome-icon :icon="['fas','arrow-trend-down']" />                    
                      <span class="text-by-side-icon">
                        {{company.lastCallsSevenDaysByOperator}}
                      </span>
                    </span>
                  </span>
                  <span
                    v-else
                     class="text-center p-34 m-2"
                    >
                    <span class="green-icon" > 
                      <font-awesome-icon :icon="['fas','arrow-trend-up']" />                    
                      <span class="text-by-side-icon">
                        {{company.lastCallsSevenDaysByOperator}}
                      </span>
                    </span>
                  </span>
            </span>
                  <span v-else>
                    <span
                      v-if="company.growthPercentageContactsSevenDays < 1"
                      class="text-center p-34 m-2"
                    >
                    <span class="red-icon">
                      <font-awesome-icon :icon="['fas','arrow-trend-down']" />                    
                      <span class="text-by-side-icon red">
                        {{company.growthPercentageContactsSevenDays}}
                      </span>
                    </span>
                  </span>
                  <span
                      v-else
                      class="text-center p-34 m-2"
                    >
                    <span class="green-icon">
                      <font-awesome-icon :icon="['fas','arrow-trend-up']" />                    
                      <span class="text-by-side-icon">
                        {{company.growthPercentageContactsSevenDays}}
                      </span>
                    </span>
                  </span>
                  </span>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Conteúdo da lista plano basic -->
      <div v-if="company.account_type === 'advanced'" class="tab-content" id="content">
      
      <!-- Integracoes -->
        <div
          class="tab-pane fade show active"
          id="integracoes"
          role="tabpanel"
          aria-labelledby="integracoes-tab"
        >
          <h3>Canal</h3>
          <div class="table-responsive">
            <table class="table table-bordered">
              <tbody>
                <tr v-for="(item, index) in integrationDataAny" :key="index">
                  <th class="text-left">{{ item.integration }}</th>
                  <th class="text-right">
                    <span v-if="item.integration === 'Whatsapp'">
                      <span
                        v-if="company.whatsapp_official_integration"
                        class="fa fa-check green-icon green-icon"
                      ></span>
                      <span v-else class="fa fa-regular fa-ban red-icon"></span>
                    </span>
                    <span v-else-if="item.integration === 'Instagram'">
                      <span
                        v-if="
                          company.instagram_integration == true ||
                            company.instagram_integration === 'true'
                        "
                        class="fa fa-check green-icon"
                      ></span>
                      <span v-else class="fa fa-regular fa-ban red-icon"></span>
                    </span>
                    <span v-else>
                      <span v-if="company.widgets" class="fa fa-check green-icon"></span>
                      <span v-else class="fa fa-regular fa-ban red-icon"></span>
                    </span>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Atendimento -->
        <div
          class="tab-pane fade"
          id="atendimento"
          role="tabpanel"
          aria-labelledby="atendimento-tab"
        >
          <h3>Tipo</h3>
          <div class="table-responsive">
            <table class="table table-bordered">
              <tbody>
                <tr v-for="(item, index) in attendanceDatasAny" :key="index">
                  <th class="text-left">{{ item.attendance }}</th>
                  <th class="text-right">
                    <span v-if="item.attendance === 'Setores'">
                      <span
                        v-if="company.departments"
                        class="fa fa-check green-icon"
                      ></span>
                      <span v-else class="fa fa-regular fa-ban red-icon"></span>
                    </span>
                    <span v-else-if="item.attendance === 'Tabulações'">
                      <span
                        v-if="company.tabulations"
                        class="fa fa-check green-icon"
                      ></span>
                      <div v-else>
                        <span class="fa fa-regular fa-ban red-icon"></span>
                      </div>
                    </span>
                    <span v-else>
                      <span
                        v-if="company.active_tags"
                        class="fa fa-check green-icon"
                      ></span>
                      <span v-else class="fa fa-regular fa-ban red-icon"></span>
                    </span>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Eficiencia -->
        <div
          class="tab-pane fade"
          id="efficiency"
          role="tabpanel"
          aria-labelledby="efficiency-tab"
        >
          <h3>Tipo</h3>
          <table class="table table-bordered">
            <tbody>
              <tr v-for="(item, index) in efficiencyDatasAny" :key="index">
                <th class="text-left">{{ item.efficiency }}</th>
                <th class="text-right">
                  <span v-if="item.efficiency === 'Variáveis Cadastradas'">
                    <span
                      v-if="company.total_variables"
                      class="fa fa-check green-icon"
                    ></span>
                    <span v-else class="fa fa-regular fa-ban red-icon"></span>
                  </span>
                  <span
                    v-else-if="
                      item.efficiecy === 'Mensagens Rápidas Cadastradas'
                    "
                  >
                    <span
                      v-if="company.trigger_messages"
                      class="fa fa-check green-icon"
                    ></span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon"></span>
                    </div>
                  </span>
                  <span v-else>
                    <span v-if="company.active_tags" class="fa fa-check green-icon"></span>
                    <span v-else class="fa fa-regular fa-ban red-icon"></span>
                  </span>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Escala -->
        <div
          class="tab-pane fade"
          id="escale"
          role="tabpanel"
          aria-labelledby="escale-tab"
        >
          <h3>Tipo</h3>
          <table class="table table-bordered">
            <tbody>
              <tr v-for="(item, index) in scaleDatasAdvanced" :key="index">
                <th class="text-left">{{ item.scale }}</th>
                <th class="text-right">
                  <span
                    v-if="
                      item.scale ===
                        '1 fluxo ou mais cadastrado e ativo na plataforma'
                    "
                  >
                    <span
                      v-if="company.activeFlows >= 1"
                      class="fa fa-check green-icon"
                    ></span>
                    <span v-else class="fa fa-regular fa-ban red-icon"></span>
                  </span>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Sucesso -->
        <div
          class="tab-pane fade"
          id="success"
          role="tabpanel"
          aria-labelledby="success-tab"
        >
          <h3>Tipo</h3>
          <table class="table table-bordered">
            <tbody>
              <tr v-for="(item, index) in successDatasAdvanced" :key="index">
                <th class="text-left">{{ item.success }}</th>
                <th class="text-center">
                  <span
                    v-if="
                      item.success ===
                        'Uma nova template cadastrada nos últimos 21 dias'
                    "
                  >
                    <span
                      v-if="company.templatesCountTwentyOneDays > 0"
                      class="fa fa-check green-icon"
                    ></span>
                    <span v-else class="fa fa-regular fa-ban red-icon"></span>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        '80% da base de usuários operadores ativos (login nos últimos 7 dias)'
                    "
                  >
                    <span
                      v-if="company.lastOperatorsAccesSevenDays >= 80"
                      class="fa fa-check green-icon"
                    ></span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon"></span>
                    </div>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        '50% da base de usuários administradores ativos (login nos últimos 7 dias)'
                    "
                  >
                    <span
                      v-if="company.lastAdminsAccesSevenDays >= 50"
                      class="fa fa-check green-icon"
                    ></span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon"></span>
                    </div>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Acesso à tela de monitoramento no mínimo uma vez nos 7 últimos dias'
                    "
                  >
                    <span
                      v-if="shouldShowMonitoringIn7Days()"
                      class="fa fa-check green-icon"
                    ></span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon"></span>
                    </div>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Acesso à tela de dashboard no mínimo uma vez nos 7 últimos dias'
                    "
                  >
                    <span
                      v-if="shouldShowDashboardIn7Days()"
                      class="fa fa-check green-icon"
                    ></span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon"></span>
                    </div>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Troca maior que 14000 interações em mensagens nos últimos 7 dias.'
                    "
                  >
                    <span
                      v-if="company.lastSevenInterations.totalMessages >= 14000"
                      class="fa fa-check green-icon"
                    >
                  </span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon">

                      </span>
                    </div>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Ao menos 50% das conversas iniciadas pelos operadores foram finalizadas nos últimos 7 dias'
                    "
                  >
                    <span
                      v-if="company.lastCallsSevenDaysByOperator >= 50"
                      class="fa fa-check green-icon"
                    ></span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon"></span>
                    </div>
                  </span>

                  <span v-else>
                    <span
                      v-if="company.growthPercentageContactsSevenDays >= 2"
                      class="fa fa-check green-icon"
                    ></span>
                    <span v-else class="fa fa-regular fa-ban red-icon"></span>
                  </span>
                </th>
                <th class="text-right">
                  <span
                    v-if="
                      item.success ===
                        'Uma nova template cadastrada nos últimos 21 dias'
                    "
                  >
                    <span
                      v-if="company.templatesCountTwentyOneDays <= 0"
                     class="text-center p-1 m-2 ml-2"
                    >
                    <span class="red-icon" > 
                      <font-awesome-icon :icon="['fas', 'arrow-trend-down']" />                     
                    </span>
                    <span class="text-by-side-icon">
                      {{formatDate(company.last_template)}}
                    </span>
                  </span>
                    <span v-else class="green-icon">
                      <font-awesome-icon :icon="['fas', 'arrow-trend-up']" />                     
                      <span class="text-by-side-icon">
                        {{ formatDate(company.last_template)}}
                        </span>
                    </span>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        '80% da base de usuários operadores ativos (login nos últimos 7 dias)'
                    "
                  >
                  <span
                      v-if="company.lastOperatorsAccesSevenDays < 80"
                     class="text-center p-34 m-2"
                    >
                    <span class="red-icon" > 
                      <font-awesome-icon :icon="['fas','arrow-trend-down']" />                     
                      <span class="text-by-side-icon">
                        {{`${company.lastOperatorsAccesSevenDays} %`}}
                      </span>
                    </span>
                  </span>
                  <span v-else class="green-icon">
                    <font-awesome-icon :icon="['fas','arrow-trend-up']" />
                    <span class="text-by-side-icon">
                        {{`${company.lastOperatorsAccesSevenDays} %`}}
                      </span>
                  </span>
                </span>
                  <span
                    v-else-if="
                      item.success ===
                        '50% da base de usuários administradores ativos (login nos últimos 7 dias)'
                    "
                  >
                  <span
                    v-if="company.lastAdminsAccesSevenDays < 50"
                     class="text-center p-34 m-2"
                    >
                    <span class="red-icon" > 
                      <font-awesome-icon :icon="['fas', 'arrow-trend-down']" />
                      <span class="text-by-side-icon">
                        {{`${company.lastAdminsAccesSevenDays} %`}}
                      </span>
                    </span>
                  </span>
                  <span v-else class="green-icon">
                    <font-awesome-icon :icon="['fas','arrow-trend-up']" />
                    <span class="text-by-side-icon">
                      {{`${company.lastAdminsAccesSevenDays} %`}}
                      </span>
                  </span>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Acesso à tela de monitoramento no mínimo uma vez nos 7 últimos dias'
                    "
                  >
                    <span
                    v-if="shouldShowMonitoringIn7Days()"
                     class="text-center p-34 m-2"
                    >
                    <span class="green-icon" > 
                      <font-awesome-icon :icon="['fas', 'arrow-trend-up']" />
                      <span class="text-by-side-icon">
                        {{formatDate(company.last_access_monitoring)}}
                      </span>
                    </span>
                  </span>
                    <span v-else class="red-icon">
                    <font-awesome-icon :icon="['fas', 'arrow-trend-down']" />
                      <span class="text-by-side-icon">
                        {{formatDate(company.last_access_dashboard)}}
                      </span>
                    </span>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Acesso à tela de dashboard no mínimo uma vez nos 7 últimos dias'
                    "
                  >
                  <span
                    v-if="shouldShowDashboardIn7Days()"
                     class="text-center p-34 m-2"
                    >
                    <span class="green-icon" > 
                    <font-awesome-icon :icon="['fas', 'arrow-trend-up']" />
                      <span class="text-by-side-icon">
                        {{formatDate(company.last_access_dashboard)}}
                      </span>
                    </span>
                  </span>
                  <span v-else>
                    <span class="red-icon" > 
                    <font-awesome-icon :icon="['fas', 'arrow-trend-down']" />
                      <span class="text-by-side-icon">
                        {{formatDate(company.last_access_dashboard)}}
                      </span>
                      </span>
                  </span>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Troca maior que 14000 interações em mensagens nos últimos 7 dias.'
                    "
                  >
                  <span
                    v-if="company.lastSevenInterations.totalMessages < 14000"
                     class="text-center p-34 m-2"
                    >
                    <span class="red-icon" > 
                      <font-awesome-icon :icon="['fas', 'arrow-trend-down']" />
                    </span>
                    <span class="text-by-side-icon">
                      {{company.lastSevenInterations.totalMessages}}
                    </span>
                  </span>
                  <span v-else>
                    <span class="green-icon" > 
                    <font-awesome-icon :icon="['fas','arrow-trend-up']" />
                  </span>
                  <span class="text-by-side-icon">
                    {{company.lastSevenInterations.totalMessages}}
                  </span>
                  </span>
                  <span>                     
                  </span>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Ao menos 50% das conversas iniciadas pelos operadores foram finalizadas nos últimos 7 dias'
                    "
                  >
                  <span
                    v-if="company.lastCallsSevenDaysByOperator < 50"
                     class="text-center p-34 m-2"
                    >
                    <span class="red-icon" > 
                    <font-awesome-icon :icon="['fas','arrow-trend-down']" />
                      <span class="text-by-side-icon">
                        {{company.lastCallsSevenDaysByOperator}}
                      </span>
                    </span>
                  </span>
                  <span
                    v-else
                     class="text-center p-34 m-2"
                    >
                    <span class="green-icon" > 
                    <font-awesome-icon :icon="['fas', 'arrow-trend-up']" />
                      <span class="text-by-side-icon">
                        {{company.lastCallsSevenDaysByOperator}}
                      </span>
                    </span>
                  </span>
                </span>
                  <span v-else>
                    <span
                      v-if="company.growthPercentageContactsSevenDays < 2"
                      class="text-center p-34 m-2"
                    >
                    <span class="red-icon">
                    <font-awesome-icon :icon="['fas', 'arrow-trend-down']" />
                      <span class="text-by-side-icon">
                        {{`${company.growthPercentageContactsSevenDays} %`}}
                      </span>
                    </span>
                  </span>
                  <span
                      v-else
                      class="text-center p-34 m-2"
                    >
                    <span class="green-icon">
                    <font-awesome-icon :icon="['fas', 'arrow-trend-up']" />
                      <span class="text-by-side-icon">
                        {{`${company.growthPercentageContactsSevenDays} %`}}
                      </span>
                    </span>
                  </span>
                  </span>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
       <!-- Conteúdo da lista plano pro -->
       <div v-if="company.account_type === 'professional'" class="tab-content" id="content">
      
      <!-- Integracoes -->
        <div
          class="tab-pane fade show active"
          id="integracoes"
          role="tabpanel"
          aria-labelledby="integracoes-tab"
        >
          <h3>Canal</h3>
          <div class="table-responsive">
            <table class="table table-bordered">
              <tbody>
                <tr v-for="(item, index) in integrationDataAny" :key="index">
                  <th class="text-left">{{ item.integration }}</th>
                  <th class="text-right">
                    <span v-if="item.integration === 'Whatsapp'">
                      <span
                        v-if="company.whatsapp_official_integration"
                        class="fa fa-solid fa-check green-icon green-icon"
                      ></span>
                      <span v-else class="fa fa-solid fa-ban red-icon red-icon"></span>
                    </span>
                    <span v-else-if="item.integration === 'Instagram'">
                      <span
                        v-if="
                          company.instagram_integration == true ||
                            company.instagram_integration === 'true'
                        "
                        class="fa fa-check green-icon green-icon"
                      ></span>
                      <span v-else class="fa fa-regular fa-ban red-icon"></span>
                    </span>
                    <span v-else>
                      <span v-if="company.widgets" class="fa fa-check green-icon"></span>
                      <span v-else class="fa fa-regular fa-ban red-icon"></span>
                    </span>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Atendimento -->
        <div
          class="tab-pane fade"
          id="atendimento"
          role="tabpanel"
          aria-labelledby="atendimento-tab"
        >
          <h3>Tipo</h3>
          <div class="table-responsive">
            <table class="table table-bordered">
              <tbody>
                <tr v-for="(item, index) in attendanceDatasAny" :key="index">
                  <th class="text-left">{{ item.attendance }}</th>
                  <th class="text-right">
                    <span v-if="item.attendance === 'Setores'">
                      <span
                        v-if="company.departments"
                        class="fa fa-check green-icon"
                      ></span>
                      <span v-else class="fa fa-regular fa-ban red-icon"></span>
                    </span>
                    <span v-else-if="item.attendance === 'Tabulações'">
                      <span
                        v-if="company.tabulations"
                        class="fa fa-check green-icon"
                      ></span>
                      <div v-else>
                        <span class="fa fa-regular fa-ban red-icon"></span>
                      </div>
                    </span>
                    <span v-else>
                      <span
                        v-if="company.active_tags"
                        class="fa fa-check green-icon"
                      ></span>
                      <span v-else class="fa fa-regular fa-ban red-icon"></span>
                    </span>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Eficiencia -->
        <div
          class="tab-pane fade"
          id="efficiency"
          role="tabpanel"
          aria-labelledby="efficiency-tab"
        >
          <h3>Tipo</h3>
          <table class="table table-bordered">
            <tbody>
              <tr v-for="(item, index) in efficiencyDatasAny" :key="index">
                <th class="text-left">{{ item.efficiency }}</th>
                <th class="text-right">
                  <span v-if="item.efficiency === 'Variáveis Cadastradas'">
                    <span
                      v-if="company.total_variables"
                      class="fa fa-check green-icon"
                    ></span>
                    <span v-else class="fa fa-regular fa-ban red-icon"></span>
                  </span>
                  <span
                    v-else-if="
                      item.efficiecy === 'Mensagens Rápidas Cadastradas'
                    "
                  >
                    <span
                      v-if="company.trigger_messages"
                      class="fa fa-check green-icon"
                    ></span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon"></span>
                    </div>
                  </span>
                  <span v-else>
                    <span v-if="company.active_tags" class="fa fa-check green-icon"></span>
                    <span v-else class="fa fa-regular fa-ban red-icon"></span>
                  </span>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Escala -->
        <div
          class="tab-pane fade"
          id="escale"
          role="tabpanel"
          aria-labelledby="escale-tab"
        >
          <h3>Tipo</h3>
          <table class="table table-bordered">
            <tbody>
              <tr v-for="(item, index) in scaleDatasProfessional" :key="index">
                <th class="text-left">{{ item.scale }}</th>
                <th class="text-right">
                  <span
                    v-if="
                      item.scale ===
                        '1 fluxo ou mais cadastrado e ativo na plataforma'
                    "
                  >
                    <span
                      v-if="company.activeFlows >= 1"
                      class="fa fa-check green-icon"
                    ></span>
                    <span v-else class="fa fa-regular fa-ban red-icon"></span>
                  </span>
                  <span
                    v-else-if="
                      item.scale ===
                        'Uso de requisições externas no fluxo do chatbot'
                    "
                  >
                    <span
                      v-if="company.externalRequestFlows === true"
                      class="fa fa-check green-icon"
                    ></span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon"></span>
                    </div>
                  </span>
                  <span v-else>
                    <span
                      v-if="company.lastCampaingBroadcastThirtyDays"
                      class="fa fa-check green-icon"
                    ></span>
                    <span v-else class="fa fa-regular fa-ban red-icon"></span>
                  </span>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Sucesso -->
        <div
          class="tab-pane fade"
          id="success"
          role="tabpanel"
          aria-labelledby="success-tab"
        >
          <h3>Tipo</h3>
          <table class="table table-bordered">
            <tbody>
              <tr v-for="(item, index) in successDatasProfessional" :key="index">
                <th class="text-left">{{ item.success }}</th>
                <th class="text-center p-3 m-2 ml-2">
                  <span
                    v-if="
                      item.success ===
                        'Uma nova template cadastrada nos últimos 14 dias'
                    "
                  >
                    <span
                      v-if="company.templatesCountFourteenDays > 0"
                      class="fa fa-check green-icon"
                    ></span>
                    <span v-else class="fa fa-regular fa-ban red-icon"></span>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        '80% da base de usuários operadores ativos (login nos últimos 7 dias)'
                    "
                  >
                    <span
                      v-if="company.lastOperatorsAccesSevenDays >= 80"
                      class="fa fa-check green-icon"
                    ></span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon"></span>
                    </div>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        '50% da base de usuários administradores ativos (login nos últimos 7 dias)'
                    "
                  >
                    <span
                      v-if="company.lastAdminsAccesSevenDays >= 50"
                      class="fa fa-check green-icon"
                    ></span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon"></span>
                    </div>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Acesso à tela de monitoramento no mínimo uma vez nos 3 últimos dias'
                    "
                  >
                    <span
                      v-if="shouldShowMonitoringIn3Days()"
                      class="fa fa-check green-icon"
                    ></span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon"></span>
                    </div>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Acesso à tela de dashboard no mínimo uma vez nos 3 últimos dias'
                    "
                  >
                    <span
                      v-if="shouldShowDashboardIn3Days()"
                      class="fa fa-check green-icon"
                    ></span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon"></span>
                    </div>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Troca maior que 21000 interações em mensagens nos últimos 7 dias. Esse valor será acrescentado 1050 interações nos últimos 7 dias por usuário operador cadastrado e ativo na empresa.'
                    "
                  >
                    <span
                      v-if="company.lastSevenInterations.totalMessages >= 21000"
                      class="fa fa-check green-icon"
                    ></span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon"></span>
                    </div>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Ao menos 50% das conversas iniciadas pelos operadores foram finalizadas nos últimos 7 dias'
                    "
                  >
                    <span
                      v-if="company.lastCallsSevenDaysByOperator >= 50"
                      class="fa fa-check green-icon"
                    ></span>
                    <div v-else>
                      <span class="fa fa-regular fa-ban red-icon"></span>
                    </div>
                  </span>

                  <span v-else>
                    <span
                      v-if="company.growthPercentageContactsSevenDays >= 3"
                      class="fa fa-check green-icon"
                    ></span>
                    <span v-else class="fa fa-regular fa-ban red-icon"></span>
                  </span>
                </th>
                <th class="text-center">
                  <span
                    v-if="
                      item.success ===
                        'Uma nova template cadastrada nos últimos 14 dias'
                    "
                  >
                    <span
                      v-if="company.templatesCountFourteenDays <= 0"
                     class="text-center p-1 m-2 ml-2"
                    >
                    <span class="red-icon d-flex" > 
                      <font-awesome-icon :icon="['fas', 'arrow-trend-down']" />
                      <span class="text-by-side-icon ml-2">
                        {{ formatDate(company.last_template)}}
                      </span>
                    </span>
                  </span>
                    <span v-else class="green-icon">
                      <font-awesome-icon :icon="['fas', 'arrow-trend-up']" />
                      <span class="text-by-side-icon">
                        {{ formatDate(company.last_template)}}
                        </span>

                    </span>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        '80% da base de usuários operadores ativos (login nos últimos 7 dias)'
                    "
                  >
                  <span
                      v-if="company.lastOperatorsAccesSevenDays < 80"
                     class="text-center p-34 m-2"
                    >
                    <span class="red-icon" > 
                      <font-awesome-icon :icon="['fas', 'arrow-trend-down']" />
                      <span class="text-by-side-icon">
                        {{`${company.lastOperatorsAccesSevenDays} %`}}
                      </span>
                    </span>
                  </span>
                    <span v-else class="fa fa-solid fa-signal green-icon">
                      <font-awesome-icon :icon="['fas', 'arrow-trend-up']" />
                    </span>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        '50% da base de usuários administradores ativos (login nos últimos 7 dias)'
                    "
                  >
                  <span
                    v-if="company.lastAdminsAccesSevenDays < 50"
                     class="text-center p-34 m-2"
                    >
                    <span class="red-icon" > 
                      <font-awesome-icon :icon="['fas', 'arrow-trend-down']" />

                      <span class="text-by-side-icon">
                        {{`${company.lastAdminsAccesSevenDays} %`}}
                      </span>
                    </span>
                  </span>
                    <span v-else class="fa fa-solid green-icon">
                      <font-awesome-icon :icon="['fas', 'arrow-trend-up'  ]" />

                    </span>
                  </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Acesso à tela de monitoramento no mínimo uma vez nos 3 últimos dias'
                    "
                  >
                    <span
                    v-if="shouldShowMonitoringIn3Days()"
                     class="text-center p-34 m-2"
                    >
                    <span class="green-icon" > 
                      <font-awesome-icon :icon="['fas', 'arrow-trend-up']" />
                      <span class="text-by-side-icon">
                        {{formatDate(company.last_access_monitoring)}}
                      </span>
                    </span>
                  </span>
                    <span v-else class="red-icon">
                      <font-awesome-icon :icon="['fas', 'arrow-trend-down']" />
                      <span class="text-by-side-icon">
                        {{formatDate(company.last_access_monitoring)}}
                      </span>

                    </span>
                  </span>
                  
                  <span
                    v-else-if="
                      item.success ===
                        'Acesso à tela de dashboard no mínimo uma vez nos 3 últimos dias'
                    "
                  >
                  <span
                    v-if="shouldShowDashboardIn3Days()"
                    class="text-center p-34 m-2"
                    >
                    <span class="green-icon" > 
                      <font-awesome-icon :icon="['fas', 'arrow-trend-up']" />
                      <span class="text-by-side-icon">
                        {{formatDate(company.last_access_dashboard)}}
                      </span>
                    </span>
                  </span>
                  <span v-else class="red-icon">
                      <font-awesome-icon :icon="['fas', 'arrow-trend-down']" />
                      <span class="text-by-side-icon">
                        {{formatDate(company.last_access_dashboard)}}
                      </span>

                    </span>
                  </span>
                  
                  <span
                    v-else-if="
                      item.success ===
                        'Troca maior que 21000 interações em mensagens nos últimos 7 dias. Esse valor será acrescentado 1050 interações nos últimos 7 dias por usuário operador cadastrado e ativo na empresa.'
                    "
                  >
                  <span
                    v-if="company.lastSevenInterations.totalMessages < 21000"
                     class="text-center p-34 m-2"
                    >
                    <span class="red-icon" > 
                      <font-awesome-icon :icon="['fas', 'arrow-trend-down']" />                     
                      <span class="text-by-side-icon red">
                        {{company.lastSevenInterations.totalMessages}}
                      </span>
                    </span>
                    
                  </span>
                  <span v-else class="green-icon">
                      <font-awesome-icon :icon="['fas', 'arrow-trend-up']" />
                      <span class="text-by-side-icon">
                        {{company.lastSevenInterations.totalMessages}}
                      </span>
                  </span>
                    </span>
                  <span
                    v-else-if="
                      item.success ===
                        'Ao menos 50% das conversas iniciadas pelos operadores foram finalizadas nos últimos 7 dias'
                    "
                  >
                  <span
                    v-if="company.lastCallsSevenDaysByOperator < 50"
                     class="text-center p-34 m-2"
                    >
                    <span class="red-icon" > 
                      <font-awesome-icon :icon="['fas', 'arrow-trend-down']" />                     
                      <span class="text-by-side-icon red">
                        {{`${company.lastCallsSevenDaysByOperator}%`}}
                      </span>
                    </span>
                  </span>
                  <span v-else>
                    <span class="green-icon" > 
                      <font-awesome-icon :icon="['fas', 'arrow-trend-up']" />                     
                      <span class="text-by-side-icon red">
                        {{`${company.lastCallsSevenDaysByOperator}%`}}
                      </span>
                  </span>
                  </span>
                </span>
                  <span v-else>
                    <span
                      v-if="company.growthPercentageContactsSevenDays < 3"
                      class="text-center p-34 m-2"
                    >
                    <span class="red-icon">
                      <font-awesome-icon :icon="['fas', 'arrow-trend-down']" />                     
                      <span class="text-by-side-icon red">
                        {{`${company.growthPercentageContactsSevenDays} %`}}
                      </span>
                    </span>
                  </span>
                  <span v-else>
                    <span class="green-icon">
                      <font-awesome-icon :icon="['fas', 'arrow-trend-up']" />                     
                      <span class="text-by-side-icon">
                        {{`${company.growthPercentageContactsSevenDays} %`}}
                      </span>
                    </span>
                  </span>
                </span>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import moment from 'moment';
export default {
  props: {
    company: {
      type: Object,
    },
    integrationDataEssential: {
      type: Array,
    },
    integrationDataAny: {
      type: Array
    },
    efficiencyDatasAny: {
      type: Array,
    },
    attendanceDatasAny: {
      type: Array,
    },
    scaleDatasAdvanced: {
      type: Array,
    },
    scaleDatasProfessional: {
      type: Array,
    },
    successDatasEssential: {
      type: Array,
    },
    successDatasAdvanced: {
      type: Array,
    },
    successDatasProfessional: {
      type: Array,
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    isWithinNDays(timestamp, n) {
      const currentTime = new Date().getTime();
      const nDaysAgo = currentTime - n * 24 * 60 * 60 * 1000;
      const targetTime = new Date(timestamp).getTime();
      return targetTime >= nDaysAgo && targetTime <= currentTime;
    },
    shouldShowDashboardIn7Days() {
      return this.isWithinNDays(this.company.last_access_dashboard, 7);
    },
    shouldShowDashboardIn3Days() {
      return this.isWithinNDays(this.company.last_access_dashboard, 3);
    },
    shouldShowMonitoringIn7Days() {
      return this.isWithinNDays(this.company.last_access_monitoring, 7);
    },
    shouldShowMonitoringIn3Days() {
      return this.isWithinNDays(this.company.last_access_monitoring, 3);
    },
  },
  };
</script>
<style scoped>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007db3df;
  border-radius: 10px;
}
.nav-pills {
  background-color: #007db324;
  border-radius: 10px;
  justify-content: center;
}
li {
  list-style: none;
}
.nav-item {
  max-width: 50%;
}
.red-icon{
color: red;
}
.green-icon{
color:rgb(60, 227, 60);
}
.text-by-side-icon{
  font-size: 14px;
  color: #222222 ;
}

.period > p{
  text-align: end;
  font-family: "Nunito Sans", sans-serif;
  color: #6C757D;
  font-size: 0.8rem;
}

</style>
